import { useStyletron } from 'baseui';
const PulsingDot = ({
  colour
}) => {
  const [css] = useStyletron();
  return <div className={css({
    position: 'absolute',
    right: '0',
    top: '0'
  })} data-testid="pulsing-dot">
      <div className={css({
      border: colour ? `2px solid ${colour}` : '2px solid #62bd19',
      borderRadius: '30px',
      height: '18px',
      width: '18px',
      position: 'absolute',
      right: '5px',
      top: '5px',
      animationDuration: '3s',
      animationIterationCount: 'infinite',
      animationName: {
        '0%': {
          transform: 'scale(0.1, 0.1)',
          opacity: '0'
        },
        '50%': {
          opacity: '1.0'
        },
        '100%': {
          transform: 'scale(1.2, 1.2)',
          opacity: '0.0'
        }
      },
      opacity: '0.0'
    })} />
      <div className={css({
      width: '8px',
      height: '8px',
      backgroundColor: colour ?? '#62bd19',
      borderRadius: '50%',
      position: 'absolute',
      top: '10px',
      right: `10px`
    })} />
    </div>;
};
export default PulsingDot;